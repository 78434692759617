import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './Login';
import UserList from './UserList';

function App() {
  const [stopTimer, setStopTimer] = useState(() => () => {});
  const handleTimerStop = () => {
    setStopTimer(() => {
      return () => {
        // Logic to stop the timer (setTimerRunning to false)
        // You can access the Layout component's state management here (if needed)
      };
    });
  };
  return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/user-list" element={<UserList />} />
          </Routes>
        </Router>
      </div>
  );
}

export default App;
