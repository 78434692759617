import React, { useState } from 'react';
import {
    Container,
    Typography,
    TextField,
    Button,
    Grid,
    Snackbar,
    Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    // State variables for username and password
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        // Check if username or password is empty
        if (!username || !password) {
            setError('Username and password are required.');
            setAlert({
                type: 'error',
                message:'Username and password are required.',
                error: 'Username and password are required.'
            });
            setAlertOpen(true);
            return;
        }
        console.log('Username:', username);
        console.log('Password:', password);
        if(username === 'admin' && password === 'cereadmin'){
            localStorage.setItem('loggedIn', 1);
            navigate('/user-list');
            return;
        }
        setAlert({
            type: 'error',
            message:'Invalid username / password.',
            error: 'Invalid username / password.'
        });
        setAlertOpen(true);
        // Add your logic here for handling form submission
    };


    // Snackbar Alert
    const [alertOpen, setAlertOpen] = useState(false);
    const [alert, setAlert] = useState({
        type: 'success',
        message: '',
        error: 'An error occurred!'
    });
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertOpen(false);
    };

    return (
        <Container maxWidth="sm" style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {/* Logo */}
            <img src="/images/bear.png" style={{ maxWidth: '20%' }} />
            {/* Login Form */}
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Username"
                            variant="outlined"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Password"
                            type="password"
                            variant="outlined"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button className='admin-submit-button' type="submit" variant="contained" color="primary" fullWidth>
                            Login
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Snackbar
                open={alertOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                autoHideDuration={6000}
                onClose={handleAlertClose}>
                <Alert
                    onClose={handleAlertClose}
                    severity={alert.type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {alert.error}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default Login;
