import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

import {
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Snackbar,
    Alert,
    CircularProgress,
    Backdrop,
} from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import axios from 'axios';

// const pages = ['Products', 'Pricing', 'Blog'];
const pages = [];
const settings = ['Logout'];

function UserList() {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    //confirmation dialog
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('loggedIn') === '1';
        if (!isLoggedIn) {
            // Redirect the user to the root route if not logged in
            navigate('/');
        }
        loadUserList();
    }, []);

    const confirmDelete = () => {
        console.log('delete');
        setOpen(false);
        deleteRecords();
    }

    const deleteRecords = () => {
        setLoading(true);
        axios.post('http://165.227.78.116:8090/delete-users').then(res => {
            console.log(res.data);
            if (res.status === 201) {
                setLoading(false);
                setAlert({
                    type: 'message',
                    message:'Compelted deleting all users!',
                    error: 'Compelted deleting all users!'
                });
                setAlertOpen(true);
                loadUserList();
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
            setAlert({
                type: 'error',
                message:'An error occurred, please try again.',
                error: 'An error occurred, please try again.'
            });
            setAlertOpen(true);
        });
    }

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const loadUserList = (data) => {
        axios.post('http://165.227.78.116:8090/list-users', data).then(res => {
            console.log(res.data);
            if (res.status === 201) {
                let users = res.data.list.map(result => {
                    let scoreDecimal = 0; // Default value for score
                    // // Check if score is not empty, null, or '0'
                    if (result.score && result.score !== '0') {
                        // Splitting the score string into minutes and seconds
                        const [minutes, seconds] = result.score.split(':');
                        // Converting minutes and seconds to decimal
                        let val = minutes + `.` + seconds;
                        scoreDecimal = parseFloat(val)
                    }
                    return {
                        id: result._id,
                        playerName: result.playerName,
                        pharmacyName: result.phramacyName,
                        contact: result.contact,
                        created: moment(result.created).local().format('YYYY-MM-DD HH:mm:ss'),
                        score: scoreDecimal,
                    }
                });
                setRows(users);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1,
            renderCell: (params) => (
                <div>{params.value}</div>
            ),
        },
        {
            field: 'playerName',
            headerName: 'Player Name',
            flex: 1,
            renderCell: (params) => (
                <div>{params.value}</div>
            ),
        },
        {
            field: 'pharmacyName',
            headerName: 'Pharmacy Name',
            flex: 1,
            renderCell: (params) => (
                <div>{params.value}</div>
            ),
        },
        {
            field: 'contact',
            headerName: 'Contact Number',
            flex: 1,
            renderCell: (params) => (
                <div>{params.value}</div>
            ),
        },
        {
            field: 'score',
            headerName: 'Score',
            flex: 1,
            renderCell: (params) => (
                <div>{params.value}</div>
            ),
        },
        {
            field: 'created',
            headerName: 'Date Added',
            flex: 1,
            renderCell: (params) => (
                <div>{params.value}</div>
            ),
        },
    ];

    // Snackbar Alert
    const [alertOpen, setAlertOpen] = useState(false);
    const [alert, setAlert] = useState({
        type: 'success',
        message: '',
        error: 'An error occurred!'
    });
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertOpen(false);
    };

    return (
        <div>
            <AppBar position="static" sx={{ backgroundColor: '#F9B233' }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                            <img src="/images/bear.png" style={{ maxHeight: '48px', marginRight: '8px' }} />
                        </Box>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            CEREGROW ADMIN
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{page}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
                            <img src="/images/bear.png" style={{ maxHeight: '48px', marginRight: '8px' }} />
                        </Box>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            CEREGROW ADMIN
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="Admin" src="/static/images/avatar/2.jpg" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Container maxWidth="lg">
                <Grid container spacing={3} sx={{ marginTop: '8px !important', justifyContent: 'flex-end' }}>
                    <Button onClick={handleClickOpen} variant="outlined" startIcon={<DeleteIcon />}>
                        Clear All Users
                    </Button>
                </Grid>
                <Grid container spacing={3} sx={{ marginTop: '8px !important' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        autoHeight
                        autoWidth
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        slots={{
                            toolbar:
                                (props) => (
                                    <div style={{ color: 'black', padding: '10px' }}>
                                        <GridToolbar {...props} sx={{
                                            '&.MuiButtonBase-root-MuiButton-root': {
                                                color: 'black'
                                            }
                                        }} />
                                    </div>
                                ),
                        }}
                        pageSizeOptions={[10, 20, 40, { value: rows.length > 0 ? rows.length : 1, label: 'All' }]}
                    />
                    {/* <DataGrid
                        sx={{
                            '&.MuiDataGrid-cellContent': {
                                padding: '15px 10px!important',
                                fontWeight: '500',
                            }
                        }}
                    rows={rows}
                    columns={columns}
                    // autoHeight
                    // autoWidth
                    disableExtendRowFullWidth
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        },
                    }}
                    slots={{
                        toolbar:
                            (props) => (
                                <div style={{ color: 'black', padding: '10px' }}>
                                    <GridToolbar {...props} sx={{
                                        '&.MuiButtonBase-root-MuiButton-root': {
                                            color: 'black'
                                        }
                                    }} />
                                </div>
                            ),
                    }}
                    pageSizeOptions={[20, 40, 60, { value: rows.length > 0 ? rows.length : 1, label: 'All' }]}
                    getRowHeight={() => 'auto'}
                    className="data-grid"
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                    }
                    /> */}
                </Grid>
            </Container>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Confirm Delete!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will delete all exisitng player records. Are you sure?
                        <p style={{ fontSize: '11px', color: 'gray' }}>
                            *You can download the list using the Export function on the table.
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete}>Delete</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={alertOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                autoHideDuration={6000}
                onClose={handleAlertClose}>
                <Alert
                    onClose={handleAlertClose}
                    severity={alert.type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {alert.error}
                </Alert>
            </Snackbar>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>

    );
}
export default UserList;
